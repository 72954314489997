.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #00539CFF;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #00539CFF;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color: #EEA47FFF;
        background: 0 0;
        font-size: 20px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 10px 35px;
        border: 1px solid #EEA47FFF;
        float: right;
        border-radius: 4px;

        &:active {
            background: #EEA47FFF;
            color: #000;
            -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
                -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
                    box-shadow: inset 0px 0px 5px #c1c1c1;
            outline: none;
        }
    }
}

@media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 400px;
    }
    
    .info-map {
      display: none;
    }
  }